/**
 IFRAME IOS HACK by David Walsh
 https://davidwalsh.name/scroll-iframes-ios
 */
.iframeScrollWrapper {
  background: white;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.iframeContent {
  height: 100%;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  position: absolute;
}

.splashScreen {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
}

.loadingWrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
